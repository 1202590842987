













































import PunishMessage from "../components/PunishMessage.vue";
import { InputSetups } from "@/mixins/input-setups";
import { MuteAction } from "../..";
import PunishTimeWrapper from "../components/PunishTimeWrapper.vue";
import { ClassProperties } from "@/includes/types/util.types";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    PunishTimeWrapper,
    PunishMessage,
    SwitchInput,
    TimeUnitInput,
    NestedContent
  }
})
export default class MuteActionView extends Mixins(InputSetups) {
  @VModel() model!: MuteAction

  @Prop() defaultModel!: Partial<ClassProperties<MuteAction>>

  @Prop({ type: Boolean, default: true, required: false }) hasMaxTimeMessage!: boolean
}
